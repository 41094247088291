<!-- Navbar-->
<header class="header">
    <nav class="navbar navbar-expand-lg navbar-light py-3">
        <div class="container">
            <!-- Navbar Brand -->
            <a href="#" class="navbar-brand">
                <img src="../../assets/img/moto.jpeg" style="float: left; height: 50px; margin-right: 2px;" class="logo" alt="">
            </a>
        </div>
    </nav>
</header>


<div class="container">
    <div class="row py-5 mt-4 align-items-center">
        <!-- For Demo Purpose -->
        <div class="col-md-5 pr-lg-5 mb-5 mb-md-0">
            <img src="https://res.cloudinary.com/mhmd/image/upload/v1569543678/form_d9sh6m.svg" alt="" class="img-fluid mb-3 d-none d-md-block">
            <h1>Create an Account</h1>
            <p class="font-italic text-muted mb-0">Create Your Accout To Enjoy this Website</p>
            <p class="font-italic text-muted">
                <a href="https://bootstrapious.com" class="text-muted">
                    <!-- <u>Bootstrapious</u> -->
                </a>
            </p>
        </div>

        <!-- Registeration Form -->
        <div class="col-md-7 col-lg-6 ml-auto">
            <div class="card">
                <h4 class="card-header">Register</h4>
                <div class="card-body">
                    <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
                        <div class="form-group">
                            <label for="firstname">First Name</label>
                            <input type="text" formControlName="firstname" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.firstname.errors }"
                            />
                            <div *ngIf="submitted && f.firstname.errors" class="invalid-feedback">
                                <div *ngIf="f.firstname.errors.required">First Name is required</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="lastname">Last Name</label>
                            <input type="text" formControlName="lastname" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.lastname.errors }"
                            />
                            <div *ngIf="submitted && f.lastname.errors" class="invalid-feedback">
                                <div *ngIf="f.lastname.errors.required">Last Name is required</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="email">Email</label>
                            <input type="text" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                            />
                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                <div *ngIf="f.email.errors.required">email is required</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="email">Phone</label>
                            <input type="text" formControlName="phone" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }"
                            />
                            <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                                <div *ngIf="f.phone.errors.required">Phonoe is required</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="username">Username</label>
                            <input type="text" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }"
                            />
                            <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                                <div *ngIf="f.username.errors.required">Username is required</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="password">Password</label>
                            <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
                            />
                            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                <div *ngIf="f.password.errors.required">Password is required</div>
                                <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <button [disabled]="loading" class="btn btn-primary">
                                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                Register
                            </button>
                            <a routerLink="/login" class="btn btn-link">Cancel</a>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>