<div class="container">
    <div class="main-body">


        <!-- /Breadcrumb -->

        <div class="row gutters-sm">
            <div class="col-md-4 mb-3">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex flex-column align-items-center text-center">
                            <img src="https://bootdey.com/img/Content/avatar/avatar7.png" alt="Admin"
                                class="rounded-circle" width="150">
                            <div class="mt-3">
                                <h4 >{{profile.FirstName}}</h4>
                                                  </div>

                        </div>
                    </div>
                    <div class="card-actions">
                        <div class="list-group list-group-flush">
                            <a (click)="changeSelectedAction(1)" style="cursor: pointer;"
                                [ngClass]="selectedAction==1?'list-group-item list-group-item-action active':'list-group-item list-group-item-action'"><svg
                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-user mr-3">
                                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                    <circle cx="12" cy="7" r="4"></circle>
                                </svg> Profile</a>
                            <a (click)="changeSelectedAction(2)" style="cursor: pointer;"
                                [ngClass]="selectedAction==2?'list-group-item list-group-item-action active':'list-group-item list-group-item-action'"><svg
                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-shopping-bag mr-3">
                                    <path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path>
                                    <line x1="3" y1="6" x2="21" y2="6"></line>
                                    <path d="M16 10a4 4 0 0 1-8 0"></path>
                                </svg> Orders</a>
                                <a (click)="changeSelectedAction(3)" style="cursor: pointer;"
                                [ngClass]="selectedAction==3?'list-group-item list-group-item-action active':'list-group-item list-group-item-action'"><svg
                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-shopping-bag mr-3">
                                    <path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path>
                                    <line x1="3" y1="6" x2="21" y2="6"></line>
                                    <path d="M16 10a4 4 0 0 1-8 0"></path>
                                </svg> Wish List</a>
                           
                            <a (click)="logout()" class="list-group-item list-group-item-action text-danger"><svg
                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-log-out mr-3">
                                    <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                                    <polyline points="16 17 21 12 16 7"></polyline>
                                    <line x1="21" y1="12" x2="9" y2="12"></line>
                                </svg> Logout</a>
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-md-8">
                <div class="card" *ngIf="selectedAction==1">
                    <div class="card-header">
                        <h5 class="title">My Profile</h5>
                    </div>


                    <div class="card-body">

                        <form class="form-style-1 font_bold ng-pristine ng-valid ng-valid-required" name="form1"
                            ng-submit="">
                            <div class="form-row">
                                <div class="form-group col-sm-6">
                                    <label for="profileFirstName">First Name</label>
                                    <input type="text" class="form-control ng-pristine ng-valid ng-valid-required"
                                        id="profileFirstName" name="profileFirstName" [(ngModel)]="profile.FirstName" required="">
                                </div>
                                <div class="form-group col-sm-6">
                                    <label for="profileLastName">Last Name</label>
                                    <input type="text" class="form-control ng-pristine ng-valid ng-valid-required"
                                        id="profileLastName" name="profileLastName" [(ngModel)]="profile.LastName" required="">
                                </div>
                                <div class="form-group col-sm-6">
                                    <label for="profileEmail">Email address</label>
                                    <input type="email"
                                        class="form-control ng-pristine ng-valid-email ng-valid ng-valid-required"
                                        id="profileEmail" name="profileEmail" [(ngModel)]="profile.Email" required="">
                                </div>
                                <div class="form-group col-sm-6">
                                    <label for="profilePhone">Phone Number</label>
                                    <input type="text" class="form-control ng-pristine ng-valid ng-valid-required"
                                        id="profilePhone" name="profilePhone" [(ngModel)]="profile.Phone" required="">
                                </div>

                                <div class="form-group col-12">
                                    <button type="submit" (click)="changeprofile()" ng-disabled="!form1.$valid"
                                        class="btn btn-primary"><i class="fa fa-check" aria-hidden="true"></i> UPDATE
                                        PROFILE</button>
                                </div>
                            </div>
                        </form>

                        <hr>
                        <form class="form-style-1 font_bold ng-pristine ng-invalid ng-invalid-required" name="form2"
                            ng-submit="">
                            <div class="form-row">
                                <hr>
                                <div class="form-group col-sm-6">
                                    <label for="profileNewPassword">Old Password</label>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"> <i class="fa fa-lock"></i> </span>
                                        </div>

                                        <input class="form-control ng-pristine ng-invalid ng-invalid-required"
                                            name="OLDPassword" id="OLDPassword" placeholder="**********"
                                            [(ngModel)]="changepass.OldPassword" type="password" required="">
                                    </div>
                                </div>
                                <div class="form-group col-sm-6">
                                    <label for="profileNewPassword">New Password</label>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"> <i class="fa fa-lock"></i> </span>
                                        </div>
                                        <input class="form-control ng-pristine ng-invalid ng-invalid-required"
                                            name="NewPassword" id="NewPassword" placeholder="***********"
                                            [(ngModel)]="changepass.NewPassword" type="password" required="">
                                    </div>
                                </div>
                                <div class="form-group col-sm-6">
                                    <label for="profileConfirmPassword">Confirm Password</label>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"> <i class="fa fa-lock"></i> </span>
                                        </div>
                                        <input class="form-control ng-pristine ng-invalid ng-invalid-required"
                                            name="ConfirmPassword" id="ConfirmPassword" placeholder="***********"
                                            [(ngModel)]="changepass.ConfirmPassword" type="password" required="">
                                    </div>
                                </div>
                                <div class="form-group col-12">
                                    <button type="submit" (click)="changePassWord()"
                                        class="btn btn-primary" ><i class="fa fa-check"
                                            aria-hidden="true"></i>
                                        UPDATE PASSWORD</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="card" *ngIf="selectedAction==2">
                    <ng-container *ngIf="!IsTableLoaded">
                        <div class="loaderCard" >
                            <div class="loader" ></div>
                            <h3 >Please Wait...</h3>
                        </div>
                    </ng-container>
                    <table class="table table-bordered table-responsive" *ngIf="IsTableLoaded">
                        <thead class="bg-info color-white" style="background-color: #d9230f !important;color: white;">
                            <tr>
                                <th>Order Id</th>
                                <th>Order Date</th>
                                <th>Order Total</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let order of allOrders">
                                <td>{{order.orderNumber}}</td>
                                <td>{{order.orderdate}}</td>
                                <td>{{order.total}}</td>
                                <td>{{order.orderstatus}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div  *ngIf="selectedAction==3">
                    <ng-container *ngIf="item.length==0&&IsInventoryLoaded">
                        <div class="loaderCard">
                            <img  src="../../assets/img/nodata-found.png">
                            <!-- <img src="/assets/img/nodata-found.png"> -->
                            <h2>No Data Found</h2>
                        </div>
                        <ng-container *ngIf="!IsInventoryLoaded">
                            <div class="loaderCard" style="padding: 27px 347px;">
                                <div class="loader" ></div>
                                <h3 >Please Wait...</h3>
                            </div>
                        </ng-container>
                    </ng-container>  
                    <h2 > Your WishList</h2>
                    <h4 style="font-size: 12px;">You Can Manage Your WishList From Here </h4> 
                          <div class="row " style="margin-top: 20px;
                          background-color: #fff;
                          background-clip: border-box;
                          border: 0 solid rgba(0,0,0,.125);
                        
                          border-radius: .25rem;display: flex;" *ngFor="let items of item">
                           
                  
                        <div class="col-4 col-md-2">
                            <figure class="itemside">
                                <div class="aside"><img src="{{items.ImagePath}}"
                                        class="border img-sm">
                                </div>
                               
                            </figure>
                        </div> <!-- col.// -->
                    
                        <div class="col-8 col-md-10">
                            <div class="row">
                                <div class="col-6">
                            <figcaption class="info">
                                <span class="text-muted">{{items.ProductName || items.productName}}</span>
                                <!-- <a routerLink="#" class="title">{{item.Description || item.productDesc}} </a> -->
                                <div class="price h5"> Rs {{items.Cost}} </div>
                               
                            </figcaption>
                          
                        </div>
                        <div class="col-3">
                            <div class="input-group input-group-sm input-spinner"  style="margin-top: 14px;" *ngIf="item.isAddedToCart">
                                <div class="input-group-prepend">
                                    <button class="btn btn-sm btn-light" (click)="updateCart('minus',items)"
                                    type="button" id="button-minus"> <i class="fa fa-minus"></i>
                                </button>
                                </div>
                                <input type="text" readonly class="form-control"
                                    value="{{items.selectedQuantity}}">
                                <div class="input-group-append">
                                    <button class="btn btn-sm btn-light" (click)="updateCart('plus',items)"
                                    type="button" id="button-plus"> <i class="fa fa-plus"></i>
                                </button>
                                  
                                </div>
                            </div>

                            <a (click)="addToCart(items)" *ngIf="!items.isAddedToCart" style="margin-top: 14px;"
                                class="btn btn-sm btn-light">
                                <i class="fa fa-shopping-cart"></i> Add
                            </a> &nbsp;
                        
                    </div>
                            <div class="col-3">
                                <div>
                            <a style="float: right;" (click)="RemoveFromWishlist(items)" class="btn btn-light"> <i class="fa fa-times"></i> </a>
                        </div>
                            
                        </div>
                        </div>
                    </div>
                    </div> 
                

                </div>
            </div>
        </div>
    </div>
</div>