import { Component, OnInit } from '@angular/core';
import { CategoryService } from '../service/category.service';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { SelectedProductService } from '../../main-content/sevices/selected-product.service';
import { Router } from '@angular/router';

import { AuthenticationService } from '../../_services';
import { User } from '../../_models';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  public categories: any = [];
  currentUser: User;
  public searchText;
  public Cartcount;
  user: any;
  public Home:boolean ;
  public Products:boolean;
  public Categories:boolean;
  constructor(private categoryService: CategoryService, private selectedProductService: SelectedProductService, private router: Router,
    private authenticationService: AuthenticationService) {
  }

  ngOnInit(): void {
    debugger;
    this.user = this.authenticationService.currentUser;
    this.categoryService.getCategories().subscribe(res => {
      this.categories = res;
    });
    // if(this.router.url == "/")
    // {
    // this.Products = false;
    // this.Categories = false;
    // this.Home = true;
    // }
   
  }
  public get count() {
    return this.selectedProductService.items.length;
  }
  searchForString(): void {

    this.selectedProductService.addSearch(this.searchText);
    if (this.router.url == "/products") {
      window.location.reload();
  
  }
  else {
    this.router.navigateByUrl('/products');
    
  }
  }
  Homes() {
    this.Home = true;
    this.Products = false;
    this.Categories = false;
 
  }
  Product() {
    this.Products = true;
    this.Categories = false;
    this.Home = false;
  
  }
  Category() {
    this.Categories = true;
    this.Products = false;
    this.Home = false;
 
  }
}
