import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainContentComponent } from '../app/main-content/main-content.component';
import { HomeComponent } from '../app/home/home.component';
import { CartComponent } from '../app/cart/cart.component';
import { AuthGuard } from './_helpers';
import { LoginComponent } from './login/login.component';
import { CustomerComponent } from '../app/customer/customer.component';
import { CheckoutComponent } from '../app/checkout/checkout.component';
import { SignupComponent } from './signup/signup.component';
import { ProductdetailComponent } from './productdetail/productdetail.component';
import { CategoryComponent } from './category/category.component';

// const routes: Routes = [
//   { path: '', component: HomeComponent, canActivate: [AuthGuard] },
//   { path: 'login', component: LoginComponent },

//   // otherwise redirect to home
//   { path: '**', redirectTo: '' }
// ];

const routes: Routes = [
  { path: "", redirectTo: "/home", pathMatch: "full" },
  { path: "home", component: HomeComponent, canActivate: [AuthGuard] },
  { path: "products", component: MainContentComponent, canActivate: [AuthGuard] },
  { path: "cart", component: CartComponent, canActivate: [AuthGuard] },
  { path: "profile", component: CustomerComponent, canActivate: [AuthGuard] },
  { path: "checkout", component: CheckoutComponent, canActivate: [AuthGuard] },
  { path: "productdetail", component: ProductdetailComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: SignupComponent },
  { path: 'category', component: CategoryComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
