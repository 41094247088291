import { Component, OnInit, ViewChild } from '@angular/core';
import { CategoryService } from '../header/service/category.service';
import { NgbCarouselConfig, NgbCarousel } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  public categories: any = [];
  @ViewChild('carousel', { static: true }) carousel: NgbCarousel;
  constructor(private categoryService: CategoryService) { }
  isLoaded = false;
  ngOnInit(): void {

    this.categoryService.getCategories().subscribe(res => {

      this.categories = res;
      this.categories.forEach(element => {
        element.categoryImage = environment.globalImagePath + 'CategoryImage/' + element.categoryImage;
      });
      this.isLoaded = true;
    });
  }
  event(event:any)
{   debugger
  let array:any=[];
  let item = event
   item.categories1.forEach((element,index) => {
       
       array.push(element.id);
   });
   array.push(item.id)
   localStorage.setItem('arrayCategories',array)
   debugger
}

}
