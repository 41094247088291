<header class="section-header sticky" >
    <section class="header-main">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-3 col-lg-3 col-12">
                    <a routerLink="/" class="brand-wrap">
                        <img class="logo" src="../../../assets/img/moto.jpeg">
                    </a> <!-- brand-wrap.// -->
                </div>
                <div class="col-md-4 col-lg-5 col-12">

                    <div class="input-group">
                        <input type="text" class="form-control" style="box-shadow: none;" (keyup.enter)="searchForString()" [(ngModel)]="searchText" placeholder="Search">
                       
                        <div class="input-group-append">
                            <button (click)="searchForString()" class="btn btn-primary" type="button">
                                <i class="fa fa-search"></i>
                            </button>
                        </div>
                    </div>

                </div> <!-- col.// -->
                <div class="col-md-5 col-lg-4 col-sm-12 col-12 text-md-right" >

                    <div style="float: left;margin: 0px 5px;">


                        <a routerLink="/cart" class="btn btn-light"> My cart <span
                                class="ml-2 badge badge-warning">{{count}}</span>
                        </a>
                        &nbsp;
                        <button class="btn float-right btn-light d-md-none" type="button" data-toggle="collapse"
                            data-target="#main_nav8" aria-controls="main_nav" aria-expanded="false"
                            aria-label="Toggle navigation">
                            <i class="fa fa-bars"></i>
                        </button>
                    </div>
                    <a routerLink="/profile" class="ml-3 icontext" aria-expanded="true" style="float: right;">
                        <div class="icon"><i class="fa fa-lg fa-user-circle"></i></div>
                        <div class="text">

                            <span>{{user.source._value.userName}} <i class="fa fa-caret-down"></i></span>
                        </div>
                    </a>
                </div> <!-- col.// -->
            </div> <!-- row.// -->
        </div> <!-- container.// -->
    </section> <!-- header-main .// -->
    <nav class="navbar navbar-main navbar-expand-md navbar-light border-top border-bottom">
        <div class="container">
            <div class="collapse navbar-collapse" id="main_nav8">
                <ul class="navbar-nav">
                    <li class="nav-item" style="cursor:pointer"> 
                        <a (click)="Homes()" class="nav-link" routerLink="/" [ngClass]="Home?'Active':''"> Home </a>
                    </li>
                    <li class="nav-item" style="cursor:pointer">
                        <a  (click)="Product()"  class="nav-link" routerLink="/products" [ngClass]="Products?'Active':''">Products</a>
                    </li>
                    <li class="nav-item dropdown" style="cursor:pointer">
                        <a (click)="Category()"  class="nav-link" routerLink="/category" [ngClass]="Categories?'Active':''" data-toggle="dropdown">Categories</a>
                      
                    </li>
                 
                </ul>

            </div> <!-- collapse .// -->
        </div> <!-- container .// -->
    </nav>

</header><!-- section-header.// -->