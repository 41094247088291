<ngb-carousel #carousel [interval]="2000" [wrap]="true" *ngIf="isLoaded">
    <ng-template ngbSlide *ngFor="let img of categories; index as i" style="margin-top: 20px;">
        <div class="carousel-caption"  >
            <h3>{{img.categoryName}}</h3>
        </div>
        <a rel="nofollow noopener noreferrer">
            <div class="picsum-img-wrapper">
                <img [src]="img.categoryImage" style="width: 100%;height: 400px;object-fit:cover;" alt=" My image {{i +
                    1}} description">
            </div>
        </a>
    </ng-template>
</ngb-carousel>
<section class="section-content padding-y-sm">
    <div class="container"> 
    </div> 
</section>
<section class="section-content">
    <div class="container">
        <div class="row">
            <div class="col-md-4 mb-3 mt-3" *ngFor="let img of categories; index as i">
                <div class="card-banner overlay-gradient" routerLink="/products" (click)="event(img)"
                    style="min-height:230px; background-image: url('{{img.categoryImage}}');">
                    <div class="card-img-overlay text-white" style="cursor: pointer;">
                        <h5 class="card-title">{{img.categoryName}}</h5>
                        <p class="card-text">{{img.description}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <header class="section-heading" style="margin-bottom: 20px;">
            <h3 class="section-title">Popular Products</h3>
        </header>
        <app-products></app-products>
    </div>
</section>