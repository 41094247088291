<div class="container" style="margin-top:20px;">
    <div class="row">
        <div class="col-md-4 order-md-2 mb-4 mt-3">
            <h4 class="d-flex justify-content-between align-items-center mb-3">
                <span class="text-muted">Your cart</span>
                <span class="badge badge-secondary badge-pill">{{allCarttems.length}}</span>
            </h4>
            <ul class="list-group mb-3">
                <li class="list-group-item d-flex justify-content-between lh-condensed"
                    *ngFor="let item of allCarttems">
                    <div>
                        <h6 class="my-0">{{item.ProductName}} &nbsp; ({{item.selectedQuantity}})</h6>
                        <small class="text-muted">{{item.Description}}</small>
                    </div>
                    <span class="text-muted">Rs {{item.Cost *
                        item.selectedQuantity}}</span>
                </li>


                <li class="list-group-item d-flex justify-content-between">
                    <span>Total (Rs)</span>
                    <strong>Rs {{TotalOfAllItems}}</strong>
                </li>
            </ul>
        </div>
        <div class="col-md-8 order-md-1">
            <h4 class="mb-3">Shipping Address</h4>
            <form class="needs-validation" #f="ngForm" novalidate>
                <div class="row">
                    <div class="col-md-6 mb-3">
                        <label for="firstName">Name</label>
                        <input type="text" class="form-control" [(ngModel)]="CustomerDetails.Name" id="firstName"
                            placeholder="" name="firstName" required>
                        <div class="invalid-feedback">
                            Valid Name is required.
                        </div>
                    </div>
                    <div class="col-md-6 mb-3">
                        <label for="lastName">Phone</label>
                        <input type="text" class="form-control" [(ngModel)]="CustomerDetails.Phone" id="phone"
                            name="phone" placeholder="" required>
                        <div class="invalid-feedback">
                            Valid Phone is required.
                        </div>
                    </div>
                </div>


                <div class="mb-3">
                    <label for="address">Address</label>
                    <input type="text" class="form-control" [(ngModel)]="CustomerDetails.Address" name="address"
                        id="address" placeholder="1234 Main St" required>
                    <div class="invalid-feedback">
                        Please enter your shipping address.
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-5 mb-3">
                        <label for="country">City</label>
                        <select class="custom-select d-block w-100" [(ngModel)]="CustomerDetails.City" id="city"
                            name="city" required>
                            <option value="">Choose...</option>
                            <option>Nathdwara</option>
                            <option>Kankroli</option>
                            <option>Udaipur</option>
                        </select>
                        <div class="invalid-feedback">
                            Please select a valid country.
                        </div>
                    </div>
                    <div class="col-md-4 mb-3">
                        <label for="state">State</label>
                        <select class="custom-select d-block w-100" [(ngModel)]="CustomerDetails.State" id="state"
                            name="state" required>
                            <option value="">Choose...</option>
                            <option>Rajasthan</option>
                        </select>
                        <div class="invalid-feedback">
                            Please provide a valid state.
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <label for="zip">Pincode</label>
                        <input type="text" class="form-control" name="zip" [(ngModel)]="CustomerDetails.Pincode"
                            id="zip" placeholder="" required>
                        <div class="invalid-feedback">
                            Zip code required.
                        </div>
                    </div>
                </div>
                <hr class="mb-4">
           <h4 class="mb-3">Payment</h4>

                <div class="d-block my-3">
                    <div class="custom-control custom-radio">
                        <input id="credit" name="paymentMethod" type="radio" class="custom-control-input" checked
                            required>
                        <label class="custom-control-label" for="credit">Cash on Delivery</label>
                    </div>
               
                </div>
              <hr class="mb-4">
                <div class="row">
                    <div class="col-6">
                <button class="btn btn-primary btn-lg btn-block"  #Shopping
                routerLink="/products" style="background: #ffc107" type="submit">Continue to shopping</button>
            </div> 
                <div class="col-6">
                <button class="btn btn-primary btn-lg btn-block" [disabled]="!f.form.valid" #postorder
                    (click)="PlaceOrder()" type="submit">Continue to
                    checkout</button>
                </div>
                </div>

            </form>
        </div>
    </div>