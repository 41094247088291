<div class="container" style="margin-top:10px" *ngIf="isLoaded">
  <h2>Product Detail</h2>
  <hr>
  <app-photo-swipe #photoSwipe></app-photo-swipe>
  <div class="row">
    <div class="col-12 col-sm-6">
        <img (click)="openSlideShow()" style="height:72%; width:100%; margin-right: 12px;"
        src="{{image}}" alt="">
      <div>
        <span>
          <img  (click)=" showImage(image)" *ngFor="let image of array"
            style="height:65px; width:65px; margin-right: 12px; margin-top: 12px;"
            src="{{image}}" alt="" />
        </span>
      </div>
      <br />
      <div>
      </div>
    </div>
    <ng-container *ngIf="!isLoaded">
      <div class="loaderCard" style="padding: 27px 347px;">
          <div class="loader" ></div>
          <h3 style="margin-left: -224px" >Please Wait...</h3>
      </div>
  </ng-container>
    <div class="col-12 col-sm-6">
      <h5 style="font-size: 20px;"> {{ItemDetails.productName}}</h5>
      <p> <b> ₹ {{ItemDetails.Cost*ItemDetails.selectedQuantity}} </b></p>
      <p><b>Description</b></p>
      <p> {{ItemDetails.productDesc}}</p>
      <p><b>Other Info</b></p>
      <p *ngFor="let attr of ItemDetails.productAttrubutes">
        <b>{{attr.attrName}}</b> : {{attr.attrvalue}}
      </p>
      <p>MOQ : {{MinimumOrderQuantity}}</p>
      <div class="input-group input-group-sm input-spinner" *ngIf="
      ItemDetails.isAddedToCart">
        <div class="input-group-prepend">
            <button class="btn btn-sm btn-light" (click)="updateCart('minus',ItemDetails)"
            type="button" id="button-minus"> <i class="fa fa-minus"></i>
        </button>
        </div>
        <input style="margin-left: -27px;" type="text" readonly class="form-control"
            value="{{ItemDetails.selectedQuantity}}">
        <div class="input-group-append">
            <button style="margin-left:-10px" class="btn btn-sm btn-light" (click)="updateCart('plus',ItemDetails)"
            type="button" id="button-plus"> <i class="fa fa-plus"></i>
        </button>
        </div>
    </div>
   <a (click)="addToCart(ItemDetails)" style="cursor: pointer;" *ngIf="!ItemDetails.isAddedToCart"
    class="btn btn-sm btn-light">
    <i class="fa fa-shopping-cart"></i> Add
</a>
   </div>
  </div>
</div>