
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { SelectedProductService } from '../../main-content/sevices/selected-product.service';
@Injectable({
  providedIn: 'root'
})
export class ProductService {


  constructor(private httpClient: HttpClient, private selectedItem: SelectedProductService) { }

  getProducts(data:any) {

    
    return this.httpClient.post<any>(environment.apiBaseUrl + 'api/Product/GetAllproduct', data);
  }

  getProductDetail(productId: number) {
    return this.httpClient.get<any>(environment.apiBaseUrl + 'api/Product/Similarproduct?productID=' + productId);
  }
  GetWishLists(userName:any) {
    return this.httpClient.get<any>(environment.apiBaseUrl + 'api/CustomerWishlist/GetWishLists?UserName=' + userName);
  }
  wishlisht(data:any)
  {
    debugger;
    return this.httpClient.post<any>(environment.apiBaseUrl + 'api/CustomerWishlist/PostWishList' ,data);
  } 
  RemoveFromWishlist(id:number)
  {
    debugger;
    return this.httpClient.post<any>(environment.apiBaseUrl + `api/WishlistDelete/DeleteWishList?id=${id}`,id);
  }

}
