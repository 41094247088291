<div class="container">
    <div class="search-results" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
        (scrolled)="onScroll()">

        <div class="row">
            <div class="col-12 col-sm-6 col-md-6" style="margin-bottom: 20px;margin-top: 20px;" *ngFor="let item of items">
                <figure class="product-list mb-3 border p-3">
                    <div class="row" style="height: 137px;">
                        <div class="col-3 col-md-3">

                            <a (click)="RouterLink(item)" class="img-wrap">
                                <img class="imges" src="{{item.ImagePath}}">
                            </a>
                            <div class="marginText">
                                <a class="btn-link badge badge-warning text-danger ">
                                    {{item.Discount}}%</a> &nbsp;

                            </div>
                        </div>
                        <div class="info-wrap col-9 col-md-9">
                            <div class="row">
                            
                            <div class="col-9 col-md-9"  >
                            <p class="title" >{{item.ProductName}} </p>
                            
                        </div>
                        <div  class="col-2 col-md-3">
                            <a (click)="addToWhishlist(item)" *ngIf="!item.wishlist" class="btn btn-sm btn-light" style="cursor: pointer;float: right;
                            margin-top: -10px;"> <i class="fa fa-heart"></i> </a>
                            <a (click)="RemoveFromWishlists(item)" *ngIf="item.wishlist" class="btn btn-sm btn-light" style="cursor: pointer;float: right;
                            margin-top: -10px;"> <i class="fa fa-heart" style="color: red;"></i> </a>
                        </div>
                        </div>
                            <small class="text-muted">{{item.CategoryName}}</small>
                            <div class="row">
                                <div class="price-wrap mb-2 col-8 col-md-8" >
                                    <span class="price">MRP: <del> ₹  {{item.newValue}}</del><a style="color: red;"> | ₹  {{item.Cost}}</a></span>
                                </div>
                                <div class="price-wrap mb-2 col-4 col-md-4">
                                    <span class="price"><a style="color: red;">₹ {{item.Cost
                                        *item.selectedQuantity}}</a></span>
                                </div>
                            </div>
                            <div class="price-wrap mb-2">
                                <span class="price"><a class="">MOQ : {{item.MinimumOrderQuantity}}</a></span>
                            </div>
                            <!-- <div class="btn-group">
                                <a href="#" class="btn btn-sm btn-light"> <i class="fa fa-shopping-cart"></i> Add </a>

                                
                            </div> -->


                            <div class="input-group input-group-sm input-spinner" style="margin-left: -151px;
                            margin-top: 20px;
                            margin-bottom: -53px;" *ngIf="item.isAddedToCart">
                                <div class="input-group-prepend">
                                    <button class="btn btn-sm btn-light" (click)="updateCart('minus',item)"
                                    type="button" id="button-minus"> <i class="fa fa-minus"></i>
                                </button>
                                </div>
                                <input type="text" class="form-control" readonly  value="{{item.selectedQuantity}}">
                                <div class="input-group-append">
                                    <button class="btn btn-sm btn-light" (click)="updateCart('plus',item)" type="button"
                                    id="button-plus"> <i class="fa fa-plus"></i>
                                </button>
                                 
                                </div>
                            </div>

                            <a style="margin-left: -142px;
                            margin-top: 17px;
                            margin-bottom: -27px;
                        " (click)="addToCart(item)" *ngIf="!item.isAddedToCart" class="btn btn-secondary btn-sm buttons">
                                <i class="fa fa-shopping-cart"></i> Add
                            </a> &nbsp;
                           

                        </div>
                    </div>
                </figure>
            </div>
        </div>
    </div>
</div>