import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class SelectedProductService {

  private _items: any = [];
  private _searchText: string = '';
  constructor() {
    this._searchText = JSON.parse(localStorage.getItem('searchText')); // get the data at lunch 
    this._items = JSON.parse(localStorage.getItem('items') || '[]'); // get the data at lunch 
  }

  remove(item) {
    const index = this._items.indexOf(item);
    this._items.splice(index, 1);
    this.syncItems();
  }

  add(item) {
    this._items.push(item);
    this.syncItems();
  }
  addSearch(search) {
    this._searchText = search;
  
    localStorage.setItem('searchText', null); // sync the data

    localStorage.setItem('searchText', JSON.stringify(this._searchText)); // sync the data

  }
  get length(): number {

    return this._items.length == undefined ? 0 : this._items.lenght;
  }

  get items() {
    return this._items.slice(0)
  }
  get _searchTextField() {

    this._searchText = JSON.parse(localStorage.getItem('searchText'));
    return this._searchText;
  }
  syncItemsWithItems(items) {
    localStorage.setItem('items', null); // sync the data
    this._items = items;
    localStorage.setItem('items', JSON.stringify(items)); // sync the data

  }
  syncItems() {
    localStorage.setItem('items', null); // sync the data
    localStorage.setItem('items', JSON.stringify(this._items)); // sync the data

  }
}
