<div class="container">
     <section class="padding-bottom" *ngFor="let item of categories">
        <header class="section-heading heading-line">
            <h4 class="title-section text-uppercase" *ngIf="item.categories1.length!=0" style="text-align: center;color: #d9230f;">{{item.categoryName}}</h4>
        </header>
        <div>
            <div class="row no-gutters">
               <div class="col-md-12">
                    <ul class="row " style="    margin-right: 10px;
                    margin-left: 10px;">
                        <li class="col-6" *ngFor="let Items of item.categories1" >
                            <a (click)="RouterLink(Items.id)" style="text-decoration: none; cursor:pointer" class="card" >
                                <div class="card-body"  >      
                                    <img (click)="openSlideShow()" style="height:80%; width:100%; margin-right: 12px; cursor: pointer;"
                                        src="{{Items.categoryImage}}" alt="">
                                 <h6 class="title" style="text-align: center;">
                                        {{Items.categoryName}}
                                    </h6>
                                    <!-- <p class="text-muted"><i class="fa fa-map-marker-alt"></i> {{Items.description}}
                                    </p> -->
                                </div>
                            </a>
                        </li>
                    </ul>
                </div> 
            </div> 
        </div>
    </section>
</div>