import { Injectable } from '@angular/core';
// import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class CheckoutService {

  constructor(private httpClient: HttpClient) { }

  PostOrder(_model) {
    return this.httpClient.post<any>(environment.apiBaseUrl + 'api/CustomerOrders/PostOrder', _model);
  }
  sendTextMessage(model:any) {
    debugger;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        
      })
    };
    debugger;
    let messageUrl='http://smsuser.stewindia.com/http-api.php?username=democust&password=432&senderid=STEWIN&route=7&number='+model.OrderCustomerData.Phone+'&message=Dear '+ model.OrderCustomerData.Name +' Your total price of item purchase is  '+ model.CartTotal +'. Thanks for visiting our website'+ model.OrderCustomerData.Phone +'We  serve  you  What you want. Have  a  great day.&templateid=1407161830900259384';
    return this.httpClient.get(messageUrl,httpOptions);
  }
}
