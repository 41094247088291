<app-header *ngIf="currentUser"></app-header>
<section class="padding-bottom">
    <router-outlet></router-outlet>
</section>

<footer class="section-footer border-top padding-y">
    <div class="container">
        <p class="float-md-right">
            © Copyright 2019 All rights reserved
        </p>
        <p>
            <a href="#">Terms and conditions</a>
        </p>
    </div><!-- //container -->
</footer>