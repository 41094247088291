import { Component, OnInit } from '@angular/core';
import { SelectedProductService } from '../main-content/sevices/selected-product.service';
import { CheckoutService } from './service/checkout.service';
import { AuthenticationService } from '../../app/_services/authentication.service';
import { NotificationService } from '../../app/_services/notification.service';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject } from 'rxjs';
import { User } from '../../app/_models';
import { Router } from '@angular/router';
@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit {
  public allCarttems: any = [];
  private User: User;
  public mainCartItem: any = [];
  public CustomerDetails = { Name: '', Phone: '', Address: '', City: '', Pincode: '', State: '', District: 'Rajsamand', UserName: '', AddressType: 'Shipping', ShippingCharge: 10 };
  public CustBillingaddress = { Name: '', Phone: '', Address: '', City: '', Pincode: '', State: '', District: '', UserName: '', AddressType: 'Billing' };
  PaymentInformation = { cardType: '2', Nameoncard: '', ExpMonth: '', ExpYear: '', CardNumber: '', CVV: '' }
  constructor(private selectedProductService: SelectedProductService, private router: Router, private notifyService: NotificationService, private authService: AuthenticationService, private checkoutService: CheckoutService) { }

  ngOnInit(): void {
debugger
    this.User = this.authService.currentUserValue;
    this.allCarttems = this.selectedProductService.items;
    this.allCarttems.forEach((element,index) => {
       if(element.selectedQuantity==0)
       {
        this.allCarttems.splice(index,1)
       }
    });
    this.getTotal();


  }
  TotalOfAllItems = 0;
  getTotal() {
    this.TotalOfAllItems = 0;
    this.allCarttems.forEach(element => {
      this.TotalOfAllItems += parseFloat(element.Cost) * parseInt(element.selectedQuantity);
    });


  }

  PlaceOrder() {

    this.CustomerDetails.UserName = this.User.userName;
    this.mainCartItem = [];
    this.allCarttems.forEach(element => {
      this.mainCartItem.push({
        Cost: element.Cost,
        Image: element.ImagePath,
        PID: element.productId,
        ProductId: element.Id,
        ProductName: element.ProductName,
        ProductQuantity: element.Quantity,
        ProductcartDescription: "",
        Quantity: element.selectedQuantity,
        discount: element.Discount,
        productDescription: element.Description,
      })
    });

    var allDataToSend = {
      OrderCartItem: this.mainCartItem, CartTotal: this.TotalOfAllItems, OrderCustomerData: this.CustomerDetails, PaymentInfo: this.PaymentInformation, CustomerBillAddress:
        this.CustBillingaddress
    };
    debugger;
    this.checkoutService.PostOrder(allDataToSend).subscribe(res => {
      debugger;
      if (res.success == true) {

        this.notifyService.showSuccess("Your order has been placed and will be processed as soon as possible !!", "Succesfully Orderd");
        this.checkoutService.sendTextMessage(allDataToSend)
        .subscribe(
          result => {
          })
        localStorage.removeItem('items');
        this.router.navigate(['/home']);
      }
      else {
        this.notifyService.showError("Something wrong with your order", "Error");
      }
    })
  }

}
