import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../_services';
import { User } from '../_models';
import { Router } from '@angular/router';
import { ToastRef } from 'ngx-toastr';
import { ToastrService } from 'ngx-toastr';
import { ProductService } from '../../app/main-content/sevices/product.service' 
import { SelectedProductService } from '../main-content/sevices/selected-product.service';
import { NotificationService } from '../../app/_services/notification.service';
import { environment } from '../../environments/environment';
@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.css']
})
export class CustomerComponent implements OnInit {
  public pageId: number = 0;
  searchText: string = '';
  selectedCategories:any = [];
  public items: any = [];
  public item:any=[];
  public wishlist:any=[];
  constructor(private notifyService: NotificationService, private selectedProductService: SelectedProductService,private productService: ProductService,private Toaster: ToastrService,private authenticationService: AuthenticationService, private router: Router) { }
  user: any;
  IsInventoryLoaded: boolean = false;
  IsTableLoaded:boolean=false;
  selectQuantity : any;
  MinimumOrderQuantity:any
  selectedAction: number = 1;//1:profile 2:orders 3:logout
  ngOnInit(): void {
debugger;

    this.user = this.authenticationService.currentUser;
    this. getuserInfo();
    this.getProducts();
  }
  getProducts() {
    debugger;
    var _model = { displayLength: 20, displayStart: this.pageId * 20, searchText: this.searchText, filtertext: '', Categories:  this.selectedCategories.toString(), lowprice: null, highprice: null, isFeatured: "0", ProductId: "" };
    this.IsInventoryLoaded = false;
    this.productService.getProducts(_model).subscribe(resp => {

      if (resp.aaData != undefined || resp.aaData.length > 0) {
        resp.aaData.forEach(element => {
          let itemToPush:any = {};
          resp.aoColumns.forEach((col, i) => {
            if (i == 13) {
              var array = element[13].split(',');
              element[i] = environment.globalImagePath + 'ProductImages/' + "Thumb/" + array[0];
            }
            itemToPush[col] = element[i];
          });

          itemToPush["isAddedToCart"] = false;
          itemToPush["addedTocart"] = true;
          itemToPush["wishlist"] = false;
          itemToPush["newValue"]=element[5]+(element[5]*element[16])/100

          if( itemToPush["MinimumOrderQuantity"]==null)
          {
            itemToPush["MinimumOrderQuantity"]=1
          }
          itemToPush["selectedQuantity"] =0 ;
         
          this.selectedProductService.items.forEach(element1 => {

            if(element1.Id==element[6]){
             itemToPush.isAddedToCart=true;
             itemToPush.selectedQuantity=element1.selectedQuantity
            }
           
         });
         this.wishlist.forEach(element1 => {

          if(element1.productattrId==element[4]){
  
              itemToPush["wishlist"]=true;
          }
         
       });
          this.items.push(itemToPush);
          this.items.forEach(element => {
            if(element.selectedQuantity==0)
      {
        element.isAddedToCart=false;
      }
          });  
        });

      }
      this.IsInventoryLoaded = true;
    });
  }
  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }
  changeSelectedAction(item) {
    this.selectedAction = item;
    if (this.selectedAction == 2)
    {
      this.getOrders();
    }
    if (this.selectedAction == 3)
    {
      this.GetWishListfromService();
    }
  }
  allOrders: any;
  profile: any;
  Model: any;
  changepass={
    ConfirmPassword: '',
NewPassword: '',
OldPassword: '',
UserName:''
  }
  getOrders() {
    this.IsTableLoaded=false
    this.authenticationService.getUserOrders().subscribe(response => {
      this.allOrders = response.data;
      this.IsTableLoaded=true
    });
  }
  addToCart(currentItem) {
    debugger;
        // currentItem.isAddedToCart = true;
        // currentItem.selectedQuantity=1
        // currentItem.ImagePath='https://admin.shivamitcs.in/ProductImages/Thumb/'+currentItem.image
        // currentItem.Cost=currentItem.productPrice
        // currentItem.Id=currentItem.productattrId
     
        if(this.selectedProductService.items=='')
        {
        
          this.selectedProductService.add(currentItem)
          this.selectedProductService.items.forEach(element => {
         
           
           
             if(element.Id==currentItem.Id)  
             {
               element.selectedQuantity=0
           }
          
           });  
        }
        
        this.selectedProductService.items.forEach(element => {
         
          if (this.isAlreadyColorName(currentItem.Id) != true)
          {
            currentItem.selectedQuantity=currentItem.selectedQuantity+currentItem.MinimumOrderQuantity;
            this.selectedProductService.add(currentItem)
          
        
        } 
          //  if(element.productattrId!=currentItem.productattrId )   
          //  {
          //   this.selectedProductService.add(currentItem)
          //  }
           else
           {
            if(element.Id==currentItem.Id)  
            {
            element.selectedQuantity=element.selectedQuantity+currentItem.MinimumOrderQuantity;
          }
           }
          });    
        
      //  });
       
        this.notifyService.showSuccess("Add to Card Succcessfully", currentItem.productName)
      }
      isAlreadyColorName(id:any)
      {
        debugger;
        for (var i = 0; i < this.selectedProductService.items.length; i++) {
          if (this.selectedProductService.items[i].Id === id) {
              return true;
          }
      }
      return false;
      }
  getuserInfo() {
    this.authenticationService.getUserInfo(this.user.source._value.userName).subscribe(response => {
      this.profile = response.data;
      this.profile = {
        FirstName: response.data[0].firstname,
        LastName:response.data[0].lastname,
        Phone:response.data[0].phone,
        Email:response.data[0].email,
      }
      debugger
    });
  }
  GetWishListfromService()
  {
    debugger;
    this.IsInventoryLoaded=false
    if(this.user.source._value.userName!=null)
    {
      this.productService.GetWishLists(this.user.source._value.userName).subscribe(resp => {
        debugger;
        this.wishlist = resp;
        this.items.forEach(element => {
            this.wishlist.forEach(elements => {
               if(elements.productattrId==element.Id)
                {
                  // elements=element
                  // let item:any=[]
                 this.item.push(element)
                  // console.log(item)
                  elements.selectedQuantity=element.selectedQuantity
                  elements.MinimumOrderQuantity=element.MinimumOrderQuantity
                  if(element.isAddedToCart==true)
                  {
                    elements.isAddedToCart=true
                  }
                }
               
            });
        });
        this.IsInventoryLoaded=true
      })

    }
  }
  updateCart(type, item) {
    debugger
    if (type == "plus") {
      item.selectedQuantity = item.selectedQuantity + item.MinimumOrderQuantity;
    } else {
      if (item.selectedQuantity >0)
        item.selectedQuantity = item.selectedQuantity -  item.MinimumOrderQuantity;
         if(item.selectedQuantity==0)
         {
          item.isAddedToCart=false;
         }
    }
    this.selectedProductService.items.forEach(element => {
      if (element.Id == item.Id) {
        element.selectedQuantity = item.selectedQuantity;
      }

    });
    this.selectedProductService.syncItems();
  }
  changeprofile()
  {
    debugger
    var Modal = this.profile;
    if (this.user != null)
    {
        Modal.UserName = this.user.source._value.userName;
    }
    // var Modal = this.Model;
    this.authenticationService.updateProfile(Modal).subscribe(response => {
      if (response.success == true) {

        this.Toaster.success(this.user.source._value.userName, "Your profile has been updated");
    }
    });
  }
  changePassWord()
  {
    debugger
    var Modal = this.changepass;
    if (this.user != null)
    {
        Modal.UserName = this.user.source._value.userName;
    }
    // var Modal = this.Model;
    this.authenticationService.ChangePass(Modal).subscribe(response => {
      if (response.success == true) {

        this.Toaster.success(this.user.source._value.userName,  "Your Password has been updated",);
    }
    else{
      this.Toaster.warning(this.user.source._value.userName,  "Something wrong!Check your passoword  Note:Error handling remaining");
      // alert("Something wrong!Check your passoword  Note:Error handling remaining")
} 
    });

    
  }
  RemoveFromWishlist(item:any)
  {
    debugger;
    var id
    this.wishlist.forEach(element => {
      if(element.productattrId==item.Id)
      {
           id=element.id
      }
    });
    this.productService.RemoveFromWishlist(id).subscribe(resp => {
      debugger;
      if (resp.success == true) {
        this.Toaster.success("Success! Wishlist Updated")
        this.item=[]
      this.GetWishListfromService();
    }
     
    })
  }
}
