<!-- Navbar-->
<header class="header">
    <nav class="navbar navbar-expand-lg navbar-light py-3">
        <div class="container">
            <!-- Navbar Brand -->
            <a href="#" class="navbar-brand">
                <img src="../../assets/img/moto.jpeg" style="float: left; height: 50px; margin-right: 2px;" class="logo" alt="">
            </a>
        </div>
    </nav>
</header>


<div class="col-md-6 offset-md-3 mt-5">
    <div class="card">
        <h4 class="p-3">Please login to continue

        </h4>
        <div class="card-body">
            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                <div class="form-group">
                    <label for="username">Username</label>
                    <input type="text" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }"
                    />
                    <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                        <div *ngIf="f.username.errors.required">Username is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="password">Password</label>
                    <input type="password" formControlName="password" *ngIf="!showPassword" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
                    />
                    <input type="text" formControlName="password" *ngIf="showPassword" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
                    />
                    <i *ngIf="showPassword" id="togglePassword" style="float: right;
                    margin-top: -29px;
                    margin-right: 17px;" (click)="showPasswords()"><svg
                        class="icon-simple icon-simple--theme01 icon-simple--show"
                        aria-labelledby="title-5fd0b5a288597" xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24" width="18" height="18">
                        <path fill="none" d="M0 0h24v24H0z"></path>
                        <path
                            d="M21 8.1a11.9 11.9 0 00-18 0A10.9 10.9 0 00.7 12 10.9 10.9 0 003 15.9a11.9 11.9 0 0018 0 10.9 10.9 0 002.3-3.9A10.9 10.9 0 0021 8.1zM12 17a9.1 9.1 0 01-8.1-5 9.1 9.1 0 0116.2 0 9.1 9.1 0 01-8.1 5z"
                            fill="currentColor"></path>
                        <circle cx="12" cy="12" r="3.5" fill="currentColor"></circle>
                    </svg></i>
                <i *ngIf="!showPassword" style="float: right;
                margin-top: -29px;
                margin-right: 17px;" id="togglePassword" (click)="showPasswords()"><svg
                        class="icon-simple icon-simple--theme01 icon-simple--hide"
                        aria-labelledby="title-5fd1bb780d44b" xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24" width="18" height="18">
                        <path fill="none" d="M0 0h24v24H0z"></path>
                        <path
                            d="M21.1 8.1a6.8 6.8 0 00-1.2-1.2L22.8 4H20L4 20h2.8l.9-.9a11.2 11.2 0 004.4.9 11.9 11.9 0 009-4.1 10.9 10.9 0 002.3-3.9 10.9 10.9 0 00-2.3-3.9zm-9 8.9a6.4 6.4 0 01-2-.3l1.3-1.3h.7a3.5 3.5 0 003.5-3.4v-.7L17.8 9a8.9 8.9 0 012.4 3 8.9 8.9 0 01-8.1 5zm-5.8-2.1A8.9 8.9 0 014.1 12a8.8 8.8 0 018-5l1.9.2 2.4-2.4a12.2 12.2 0 00-4.3-.8 11.9 11.9 0 00-9 4.1A10.9 10.9 0 00.8 12a10.9 10.9 0 002.3 3.9L4.2 17z"
                            fill="currentColor"></path>
                        <path d="M8.6 12c0 .2.1.3.1.5l3.9-4h-.5A3.5 3.5 0 008.6 12z" fill="currentColor"></path>
                    </svg></i>
                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                        <div *ngIf="f.password.errors.required">Password is required</div>
                    </div>
                </div>
                <button [disabled]="loading" class="btn btn-primary">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    Login
                </button>
                <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
            </form>
        </div>
    </div>
    <div class="form-group col-lg-12 mx-auto d-flex align-items-center my-4">
        <div class="border-bottom w-100 ml-5"></div>
        <span class="px-2 small text-muted font-weight-bold text-muted">OR</span>
        <div class="border-bottom w-100 mr-5"></div>
    </div>

    <!-- Social Login -->
    <!-- <div class="form-group col-lg-12 mx-auto">
                            <a href="#" class="btn btn-primary btn-block py-2 btn-facebook">
                                <i class="fa fa-facebook-f mr-2"></i>
                                <span class="font-weight-bold">Continue with Facebook</span>
                            </a>
                            <a href="#" class="btn btn-primary btn-block py-2 btn-twitter">
                                <i class="fa fa-twitter mr-2"></i>
                                <span class="font-weight-bold">Continue with Twitter</span>
                            </a>
                        </div> -->

    <!-- Already Registered -->
    <div class="text-center w-100">
        <p class="text-muted font-weight-bold">Not have an account?
            <a href="/register" class="text-primary ml-2">Register</a>
        </p>
    </div>
</div>