import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StateService {
  private dataToPass: any = {};
  constructor() {
    this.dataToPass = {};
  }
  setState(stateData) {
    this.dataToPass = stateData;
  }
  get stateDate() {
    return this.dataToPass;
  }
}
