import { Component, OnInit } from '@angular/core';
import { CategoryService } from '../header/service/category.service';
import { environment } from '../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit {
  public categories: any = [];
  GlobalImage;
  constructor(private route: Router, private categoryService: CategoryService) { }

  ngOnInit(): void {


    this.categoryService.getCategories().subscribe(res => {
      debugger;
      this.categories = res;
      this.categories.forEach(element => {
        element.categoryImage = environment.globalImagePath + 'CategoryImage/' + element.categoryImage;
        element.categories1.forEach(element => {
          element.categoryImage = environment.globalImagePath + 'CategoryImage/' + element.categoryImage;
        });
      });

    });
  }
  RouterLink(id) {
    this.route.navigate(['/products'], { queryParams: { Id: id } });
  }


}
