import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { SelectedProductService } from '../main-content/sevices/selected-product.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { element } from 'protractor';
@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit {
  public allCarttems: any = [];
  public ImagePath

  constructor(private toastr: ToastrService,private router: Router, private selectedProductService: SelectedProductService, private cdr: ChangeDetectorRef) { }
  public TotalOfAllItems = 0;
  ngOnInit(): void {
    debugger;
    var manage = []
    // manage = this.selectedProductService.items;
    // manage.forEach(elements=>{
    //      this.allCarttems.items.forEach(element => {
    //           if(element.ROWID!=elements.ROWID)
    //           {
    //              this.allCarttems.push(element)
    //           }
    //           else
    //           {
    //             element.selectedQuantity=element.selectedQuantity+elements.selectedQuantity
    //           }
    //      });
    // })
    this.allCarttems = this.selectedProductService.items;
    
    // this.selectedProductService.items.forEach(element => {
    //   if (element.Id == currentItem.Id) {
    //     element.selectedQuantity = currentItem.selectedQuantity;
    //   }
    //   else
    //   {
    
    //   }

    // });
   
    this.allCarttems.forEach(element => {
      this.ImagePath = element.ImagePath;
  
    });

    this.cdr.detectChanges();
    this.getTotal();
  }
  remove(currentItem) {
    if (this.allCarttems.length > 0) {
      this.selectedProductService.remove(currentItem);
      this.allCarttems = this.selectedProductService.items;
      this.getTotal();
    }
  }
  getTotal() {
    this.TotalOfAllItems = 0;
    this.allCarttems.forEach(element => {
      this.TotalOfAllItems += parseFloat(element.Cost ) * parseInt(element.selectedQuantity);
    });

    this.cdr.detectChanges();
  }
  updateCart(type, item) {
    debugger;
    if (type == "plus") {
      item.selectedQuantity = item.selectedQuantity + item.MinimumOrderQuantity;
    } else {
      if (item.selectedQuantity > 0)
        item.selectedQuantity = item.selectedQuantity - item.MinimumOrderQuantity;
    }
    this.getTotal();

  }
  purchaseItems() {
    this.selectedProductService.syncItemsWithItems(this.allCarttems);
    if(this.TotalOfAllItems!=0)
    {
    this.router.navigateByUrl("/checkout");
  }
  else
  {
    if(this.allCarttems==0)
    {
      this.toastr.warning('Do Not Have Any Data');
    }
    else
    {
      if(this.TotalOfAllItems==0)
      {
        this.toastr.warning('Your Purchase Quantity Is Zero It Should Be Greater Than Zero');
      }
    }
  }
  }
  
}
