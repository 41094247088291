import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { JsonPipe } from '@angular/common';
import Swal from 'sweetalert2'
import { SelectedProductService } from '../main-content/sevices/selected-product.service';
import { StateService } from '../main-content/sevices/state.service';
import { ProductService } from '../main-content/sevices/product.service';
import { NotificationService } from '../../app/_services/notification.service';
import PhotoSwipe from 'photoswipe';
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';
import { PhotoSwipeComponent } from './photo-swipe/photo-swipe.component';
import { AuthenticationService } from '../_services';
export interface IImage {
    src: string,
    w: number,
    h: number,
}
@Component({
    selector: 'app-productdetail',
    templateUrl: './productdetail.component.html',
    styleUrls: ['./productdetail.component.scss']
})

export class ProductdetailComponent implements OnInit {
    @ViewChild('photoSwipe') photoSwipe: PhotoSwipeComponent;
    @Input() public item: any;
    @Input() public state: string;
    @Output() event: EventEmitter<string> = new EventEmitter();

    @Input() images: IImage[] = [];
    public pageId: number = 0;
    searchText: string = '';
    IsInventoryLoaded: boolean = false;
    public goodbye = "Goodbye friends";
    public ItemDetails: any;
    localProductDetail;
    user: any;
    ProductName;
    GlobalImage;
    public MinimumOrderQuantity:any;
    similarproduct;
    allProductDetailNewWithAttr = [];
    selectedCategories:any = [];
    SelecteditemDetail = [];
    public wishlist:any=[];
    AllCartItems = [];
    public CartProductsCounter = 0;
    shoppingCart = [];
    public items: any = [];
    showloader = true;
    public array: any=[];
    public detailItem: any;
    image: any;
    constructor(private authenticationService: AuthenticationService,private route: ActivatedRoute, private productService: ProductService, private notifyService: NotificationService, private stateService: StateService, private router: Router, private selectedProductService: SelectedProductService) {
    }

    isLoaded = false;

    productId: any = {};
    ngOnInit() {
        this.user = this.authenticationService.currentUser;
        debugger;
        this.getProducts();
        this.GetWishListfromService();
       this.MinimumOrderQuantity= localStorage.getItem('MinimumOrderQuantity')
        this.route.queryParamMap
            .subscribe((params) => {

                var allPrams = { ...params.keys, ...params };
                this.productId = allPrams["params"].Id;
                this.productService.getProductDetail(parseInt(this.productId)).subscribe(resp => {

                    this.ItemDetails=resp.allAttributes[0];
                    this.ItemDetails.Cost=this.ItemDetails.productprice
                    this.ItemDetails.MinimumOrderQuantity=parseInt(this.MinimumOrderQuantity)
                   this.ItemDetails.selectedQuantity = 0;
                   this.selectedProductService.items.forEach(element1 => {

                    if(element1.Id==this.ItemDetails.id){
                        this.ItemDetails.isAddedToCart=true;
                        this.ItemDetails.selectedQuantity=element1.selectedQuantity
                    }
                   });
                    this.ItemDetails.productImage.forEach(element => {
                        this.GlobalImage = "https://admin.shivamitcs.in";
                        this.images.push({
                            src: this.GlobalImage + "/ProductImages/Large/" + element.imagepath,
                            w: 1200,
                            h: 900
                        });
                        if (element.imagepath != undefined) {
                            this.GlobalImage = "https://admin.shivamitcs.in";
                            element.imagepath= this.GlobalImage + "/ProductImages/Large/" + element.imagepath;
                           this.array.push( element.imagepath)
                           this.image=this.array[0]
                          } else {
                      return "../img/no-image.png";
                        }
                    });
                    this.isLoaded = true;
               });
            }
            );
            
    }
    getProducts() {
        debugger;
        var _model = { displayLength: 20, displayStart: this.pageId * 20, searchText: this.searchText, filtertext: '', Categories:  '', lowprice: null, highprice: null, isFeatured: "0", ProductId: "" };
        this.IsInventoryLoaded = false;
        this.productService.getProducts(_model).subscribe(resp => {
          
          if (resp.aaData != undefined || resp.aaData.length > 0) {
            resp.aaData.forEach(element => {
              let itemToPush:any = {};
              resp.aoColumns.forEach((col, i) => {
                if (i == 13) {
                  var array = element[13].split(',');
                  element[i] = environment.globalImagePath + 'ProductImages/' + "Thumb/" + array[0];
                }
                itemToPush[col] = element[i];
              });
    
              itemToPush["isAddedToCart"] = false;
              itemToPush["addedTocart"] = true;
              itemToPush["wishlist"] = false;
              itemToPush["newValue"]=element[5]+(element[5]*element[16])/100
    
              if( itemToPush["MinimumOrderQuantity"]==null)
              {
                itemToPush["MinimumOrderQuantity"]=1
              }
              itemToPush["selectedQuantity"] =0 ;
             
              this.selectedProductService.items.forEach(element1 => {
    
                if(element1.Id==element[6]){
                 itemToPush.isAddedToCart=true;
                 itemToPush.selectedQuantity=element1.selectedQuantity
                }
               
             });
             this.wishlist.forEach(element1 => {
    
              if(element1.productattrId==element[4]){
      
                  itemToPush["wishlist"]=true;
              }
             
           });
              this.items.push(itemToPush);
              this.items.forEach(element => {
                if(element.selectedQuantity==0)
          {
            element.isAddedToCart=false;
          }
              });  
            });
    
          }
          this.IsInventoryLoaded = true;
        });
      }
    openSlideShow() {
        debugger;
        this.photoSwipe.openGallery(this.images);

    }

    sendtoparent() {
        this.event.emit(this.goodbye);
    }

    showImage(data:any)
    {
      this.image=data
       }

    
    updateCart(type, item) {
debugger;

        if (type == "plus") {
            item.selectedQuantity = item.selectedQuantity +  item.MinimumOrderQuantity;
        } else {
            if (item.selectedQuantity >0)
            item.selectedQuantity = item.selectedQuantity -  item.MinimumOrderQuantity;
            if(item.selectedQuantity==0)
            {
             item.isAddedToCart=false;
            }
        }
        this.selectedProductService.items.forEach(element => {
            if (element.Id == item.id) {
              element.selectedQuantity = item.selectedQuantity;
            }
      
          });
        this.selectedProductService.syncItems();
       }
    addToCart(currentItem) {
debugger;
        currentItem.isAddedToCart = true;
        currentItem.Id=currentItem.id
        this.ItemDetails.selectedQuantity = parseInt(this.MinimumOrderQuantity)
      
        currentItem.ImagePath=  this.array[0];  
         this.selectedProductService.add(currentItem);
        this.notifyService.showSuccess("Add to Card Succcessfully", currentItem.ProductName)
    }

    
    GetWishListfromService()
    {
      debugger;
      if(this.user.source._value.userName!=null)
      {
        this.productService.GetWishLists(this.user.source._value.userName).subscribe(resp => {
          debugger;
          this.wishlist = resp;
          this.wishlist.forEach(element1 => {
            this.items.forEach(element => {
              if(element1.productattrId== element.Id){
              
                   element.wishlist=true;
                }  
            });
            
           
         });
     
        })
  
      }
    }
}
