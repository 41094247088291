import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class CategoryService {


  constructor(private httpClient: HttpClient) { }

  getCategories() {
    return this.httpClient.get<any>(environment.apiBaseUrl + 'api/Categories/GetCategories');
  }



}
