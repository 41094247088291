import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { ProductService } from './sevices/product.service';
import { environment } from '../../environments/environment';
import { CategoryService } from '../header/service/category.service';
import { TreeviewItem, TreeviewConfig } from 'ngx-treeview';
import { SelectedProductService } from '../main-content/sevices/selected-product.service';
import { NotificationService } from '../../app/_services/notification.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../_services';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-main-content',
  templateUrl: './main-content.component.html',
  styleUrls: ['./main-content.component.css']
})
export class MainContentComponent implements OnInit {
  public pageId: number = 0;
  @Input() showCategoryFilter: boolean;
  public items: any = [];
  user: any;
  searchText: string = '';
  selectedCategories1 = [];
  selectedCategories:any = [];
  dropdownEnabled = true;
  public allPrams: any
  newItems: TreeviewItem[] = [];
  categories: any;
  values: number[];
  config = TreeviewConfig.create({
    hasAllCheckBox: true,
    hasFilter: true,
    hasCollapseExpand: true,
    decoupleChildFromParent: false,
    maxHeight: 400
  });
  IsInventoryLoaded: boolean = false;
  myArray: any=[];
  public wishlist:any=[];
  Catgoryfiltersummery: any=[];
  category: any=[];
  constructor(private Toaster: ToastrService,private authenticationService: AuthenticationService,private route: ActivatedRoute, private router: Router, private productService: ProductService, private cdr: ChangeDetectorRef, private notifyService: NotificationService, private categoryService: CategoryService, private selectedProductService: SelectedProductService) {
    this.searchText = selectedProductService._searchTextField;
    
  }
  onScroll() {

    this.pageId = this.pageId + 1;
    
    this.getProducts();

  }

  updateCart(type, item) {
    debugger
    if (type == "plus") {
      item.selectedQuantity = item.selectedQuantity + item.MinimumOrderQuantity;
    } else {
      if (item.selectedQuantity >0)
        item.selectedQuantity = item.selectedQuantity -  item.MinimumOrderQuantity;
         if(item.selectedQuantity==0)
         {
          item.isAddedToCart=false;
         }
    }
    this.selectedProductService.items.forEach(element => {
      if (element.Id == item.Id) {
        element.selectedQuantity = item.selectedQuantity;
      }

    });
    this.selectedProductService.syncItems();
  }

  isLoaded = false;
  ngOnInit(): void {
    debugger
   
    
    this.user = this.authenticationService.currentUser;
    this.IsInventoryLoaded = false;
    this.selectedCategories=localStorage.getItem('arrayCategories')
    if(this.selectedCategories!=null)
    {
    this.myArray = this.selectedCategories.split(',');
    this.selectedCategories=this.myArray
  }
    localStorage.removeItem('arrayCategories')
    this. GetWishListfromService(); 
    this.categoryService.getCategories().subscribe(res => {
       this.category=res
      res.forEach(element => {
        let currentItem = new TreeviewItem({
          text: element.categoryName,
          value: element.id,
          checked: false
        });
        this.newItems.push(currentItem);
        if (element.categories1 != undefined && element.categories1.length > 0) {
          element.categories1.forEach(elementinner => {
            if (currentItem.children == undefined) {
              currentItem.children = [new TreeviewItem({
                text: elementinner.categoryName,
                value: elementinner.id,
                checked: false
              })];
            } else {
              currentItem.children.push(new TreeviewItem({
                text: elementinner.categoryName,
                value: elementinner.id,
                checked: false
              }));
            }
          });
        }

      });

      this.isLoaded = true;
       if(this.selectedCategories!=null)
      {     
         this.newItems.forEach(item => {
        this.selectedCategories.forEach(element => {
          if (item.value ==element) { item.checked = true; }
          item.children.forEach(inner => {
            if (inner.value ==element) { inner.checked = true; }
          });  
        });
        
      });
    }
      this.route.queryParamMap
        .subscribe((params) => {
          this.allPrams = { ...params.keys, ...params };

          let catId = this.allPrams["params"].Id;
          if (catId != undefined) {
            this.newItems.forEach(item => {
              if (item.value == catId) { item.checked = true; }
              item.children.forEach(inner => {
                if (inner.value == catId) { inner.checked = true; }
              });
            });
        
          } else {
            this.getProducts();
          }

        }

        );
    });



    this.showCategoryFilter = this.showCategoryFilter == undefined ? true : this.showCategoryFilter;


  }
  onFilterChange(value: string): void {
    
    console.log('filter:', value);
  }

  addToCart(currentItem) {
debugger;
    currentItem.isAddedToCart = true;
    currentItem.selectedQuantity=currentItem.MinimumOrderQuantity
 
    if(this.selectedProductService.items=='')
    {
    
      this.selectedProductService.add(currentItem)
      this.selectedProductService.items.forEach(element => {
     
       
       
         if(element.Id==currentItem.Id)  
         {
           element.selectedQuantity=0
       }
      
       });  
    }
    
    this.selectedProductService.items.forEach(element => {
     
      if (this.isAlreadyColorName(currentItem.Id) != true)
        {
          this.selectedProductService.add(currentItem)
        
      
      } 
       else
       {
        if(element.Id==currentItem.Id)  
        {
        element.selectedQuantity=element.selectedQuantity+currentItem.MinimumOrderQuantity;
      }
       }
      });    
    
  //  });
   
    this.notifyService.showSuccess("Add to Card Succcessfully", currentItem.ProductName)
  }
  isAlreadyColorName(id:any)
  {
    debugger;
    for (var i = 0; i < this.selectedProductService.items.length; i++) {
      if (this.selectedProductService.items[i].Id === id) {
          return true;
      }
  }
  return false;
  }
  RemoveFromWishlist(item:any)
  {
    debugger;
    var id
    this.wishlist.forEach(element => {
      if(element.productattrId==item.Id)
      {
           id=element.id
      }
    });
    this.productService.RemoveFromWishlist(id).subscribe(resp => {
      debugger;
      if (resp.success == true) {
        this.productService.GetWishLists(this.user.source._value.userName).subscribe(resp => {
          debugger;
          this.wishlist = resp;
          if(this.wishlist.length==0)
          {
            this.items.forEach(element => {
              
              
                   element.wishlist=false;
            
              
            });
            
          }
          this.wishlist.forEach(element1 => {
            this.items.forEach(element => {
              if(element1.productattrId==element.Id){
              
                   element.wishlist=true;
                }  
                else
                {
                  if(element1.productattrId!=element.Id)
                  {
                    if(element.Id==item.Id)
                    {
                    element.wishlist=false;
                  }
                  }
                }
               
            });
            
           
         });
        
        })
        this.Toaster.success("Success! Wishlist Updated")
      
    }
     
    })
  }
  onSelectedChange(value: any): void 
  {
      debugger
          if(value!='')
                  {
                      this.searchText='';
                      this.selectedProductService.addSearch('');
                  }
             
          this.pageId = 0;
          this.items = [];
           if (value.length > 0) {
                  this.selectedCategories = value;
                  }
            if (value.length == 0) {
                   this.selectedCategories = [];
                  }
                    
                  this.Catgoryfiltersummery=[]
                  this.category.forEach(element => {
                
                    element.categories1.forEach(elements => {
                      this.selectedCategories.forEach(element => {
                        if(elements.id==element)
                        {
                         
                          this.Catgoryfiltersummery.push({Id:elements.id,Name:elements.categoryName})
                        }   
                      }); 
                     
                    });
                });
            this.getProducts();
  }
  selectCategory(value)
  {
    debugger;
 if(this.selectedCategories!=[])
                  {
                    this.selectedCategories.forEach((element,index) => {
                       if(element==value)
                       {
                         this.selectedCategories.splice(index,1)
                       }
                    });
                  }
                  this.Catgoryfiltersummery=[]
                  this.category.forEach(element => {
               
                    element.categories1.forEach(elements => {
                      this.selectedCategories.forEach(element => {
                        if(elements.id==element)
                        {
                         
                          this.Catgoryfiltersummery.push({Id:elements.id,Name:elements.categoryName})
                        }   
                      }); 
                     
                    });
                });
                this.items=[]
                this.getProducts();

                this.newItems.forEach(item => {
             
                  item.children.forEach(inner => {
                    if(inner.value==value)
                    {
                   inner.checked = false; 
                  }
                  if(inner.checked==false)
                  {
                    item.checked=false
                  }
                  else
                  {
                    item.checked=true
                  }
                  });
                })
            
  }
  Clear()
  {
    debugger;
    this.searchText='';
    this.Catgoryfiltersummery=[]
    this.selectedProductService.addSearch('');
    this.selectedCategories=[];
    this.onSelectedChange(this.selectedCategories)
  
    this.newItems.forEach(item => {
      item.checked = false; 
      item.children.forEach(inner => {
       inner.checked = false; 
      });
    })
 

    localStorage.setItem('arrayCategories','')
    this.getProducts()
  }
  getProducts() {
    debugger;
    var _model = { displayLength: 20, displayStart: this.pageId * 20, searchText: this.searchText, filtertext: '', Categories:  this.selectedCategories.toString(), lowprice: null, highprice: null, isFeatured: "0", ProductId: "" };
    this.IsInventoryLoaded = false;
    this.productService.getProducts(_model).subscribe(resp => {
      
      if (resp.aaData != undefined || resp.aaData.length > 0) {
        resp.aaData.forEach(element => {
          let itemToPush:any = {};
          resp.aoColumns.forEach((col, i) => {
            if (i == 13) {
              var array = element[13].split(',');
              element[i] = environment.globalImagePath + 'ProductImages/' + "Thumb/" + array[0];
            }
            itemToPush[col] = element[i];
          });

          itemToPush["isAddedToCart"] = false;
          itemToPush["addedTocart"] = true;
          itemToPush["wishlist"] = false;
          itemToPush["newValue"]=element[5]+(element[5]*element[16])/100

          if( itemToPush["MinimumOrderQuantity"]==null)
          {
            itemToPush["MinimumOrderQuantity"]=1
          }
          itemToPush["selectedQuantity"] =0 ;
         
          this.selectedProductService.items.forEach(element1 => {

            if(element1.Id==element[6]){
             itemToPush.isAddedToCart=true;
             itemToPush.selectedQuantity=element1.selectedQuantity
            }
           
         });
         this.wishlist.forEach(element1 => {

          if(element1.productattrId==element[4]){
  
              itemToPush["wishlist"]=true;
          }
         
       });
          this.items.push(itemToPush);
          this.items.forEach(element => {
            if(element.selectedQuantity==0)
      {
        element.isAddedToCart=false;
      }
          });  
        });

      }
      this.IsInventoryLoaded = true;
    });
  }
  RouterLink(item) {
    debugger;
    localStorage.setItem('MinimumOrderQuantity',item.MinimumOrderQuantity)
    this.router.navigate(['/productdetail'], { queryParams: { Id: item.Id } });
    
  }
  addToWhishlist(item:any)
  {
    debugger;
    localStorage.setItem('wishlistselectQuantity',item.selectedQuantity)
    localStorage.setItem('wishlistMinimumOrderQuantity',item.MinimumOrderQuantity)
    var wishListmodel = { ProductId: item.Id, Productattrid: item.Id, CustomerId: -1, UserID: this.user.source._value.userName };
    this.productService.wishlisht(wishListmodel).subscribe(resp => {
      debugger;
      if (resp.success == true) {

        this.Toaster.success("is added to Wishlist !",item.ProductName);
     
       this.GetWishListfromService();
      
      }
    })
  }
  GetWishListfromService()
  {
    debugger;
    if(this.user.source._value.userName!=null)
    {
      this.productService.GetWishLists(this.user.source._value.userName).subscribe(resp => {
        debugger;
        this.wishlist = resp;
        this.wishlist.forEach(element1 => {
          this.items.forEach(element => {
            if(element1.productattrId== element.Id){
            
                 element.wishlist=true;
              }  
          });
          
         
       });
   
      })

    }
  }

}
