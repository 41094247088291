import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { User } from '../../app/_models';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    login(username: string, password: string) {
        let data = "grant_type=password&username=" + username + "&password=" + password;
        return this.http.post<any>(`${environment.apiBaseUrl}token`, data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
            .pipe(map(user => {

                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentUser', JSON.stringify(user));
                this.currentUserSubject.next(user);
                this.getUserInfo(user.userName);
                return user;
            }));
    }

    getUserInfo(userName: string) {
        debugger;
        return this.http.get<any>(environment.apiBaseUrl + 'api/CustomerWishlist/GetCustomerinfo/?userName='+ userName);
      
    }

    getUserOrders() {
        return this.http.get<any>(environment.apiBaseUrl + 'api/CustomerOrders/GetOrder?UserName=' + this.currentUserValue.userName);
    }


    updateProfile(usersInfo: any)
    {
        debugger;
        return this.http.post<any>(`${environment.apiBaseUrl}api/Profile/UpdateProfiles`, usersInfo);
    }
    ChangePass(ChangePass: any)
    {
        debugger;
        return this.http.post<any>(`${environment.apiBaseUrl}api/Account/ChangePassword`, ChangePass);
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
    }
    register(usersInfo: any) {
        return this.http.post<any>(`${environment.apiBaseUrl}api/Account/Register`, usersInfo);
    }
}