<div class="container">
    <h2>Shopping Cart</h2>
    <hr>
    <div class="row">
        <main class="col-md-9">
            <ng-container *ngIf="allCarttems.length==0">
                <div class="loaderCard">
                    <img style="margin-left: 500px;" src="../../assets/img/nodata-found.png">
                    <h2 style="margin-left: 500px;">No Data Found</h2>
                </div>
            </ng-container>
            <article class="card card-body mb-3"  *ngFor="let item of allCarttems">
                <div class="row">
                    <div class="col-4 col-md-2">
                        <figure class="itemside">
                            <div class="aside"><img src="{{item.ImagePath}}"
                                    class="border img-sm">
                            </div>
                           
                        </figure>
                    </div> 
                    <div class="col-8 col-md-10">
                        <div class="row">
                            <div class="col-8">
                        <figcaption class="info">
                            <span class="text-muted">{{item.ProductName || item.productName}}</span>
                            <div class="price h5"> Rs {{item.Cost * item.selectedQuantity }} </div>
                            <span class="text-muted">MOQ : {{item.MinimumOrderQuantity}}</span>
                        </figcaption>
                      
                    </div>
                        <div class="col-4">
                            <div>
                        <a style="float: right;" (click)="remove(item)" class="btn btn-light"> <i class="fa fa-times"></i> </a>
                    </div>
                        <div >
                        <div  class="input-group input-spinner" >
                            <div class="input-group-prepend">
                                <button class="btn btn-light" (click)="updateCart('minus',item)" type="button"
                                id="button-minus"> <i class="fa fa-minus"></i>
                            </button>
                            </div>
                            <input type="text" readonly class="form-control" value="{{item.selectedQuantity}}">
                            <div class="input-group-append">
                                <button class="btn btn-light" (click)="updateCart('plus',item)" type="button"
                                id="button-plus"> <i class="fa fa-plus"></i>
                            </button>
                                
                            </div>
                        </div>
                    </div>
                    </div>
                    </div>
                </div>
                </div> 
              
            </article>

        </main>
        <aside class="col-md-3" *ngIf="allCarttems!=0">
            <div class="card">
                <div class="card-body">
              
                    <dl class="dlist-align">
                        <dt>Grand Total:</dt>
                        <dd class="text-right text-dark"><strong>Rs {{TotalOfAllItems}}</strong></dd>
                    </dl>
                    <hr>
                    <a (click)="purchaseItems()" class="btn btn-primary btn-block"> Purchase </a>
                </div> 
            </div> 
        </aside> 
    </div> 
</div>