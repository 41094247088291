<div class="container">
    <h2>&nbsp;
   
</h2>
    <!-- <hr > -->
    <div class="row">

        <div class="col-md-3">
            <article class="list-group-item">
                <header class="filter-header mb-2 p-2">
                    <a routerLink="#" data-toggle="collapse" data-target="#collapse1" aria-expanded="true" class="">
                        <i class="icon-control fa fa-chevron-down"></i>
                        <h6 class="title">Categories </h6>
                    </a>
                </header>
                <div class="filter-content collapse show" id="collapse1" style="margin-left: 14px;">

                    <ngx-treeview style="margin-left: 0rem;" *ngIf="isLoaded" [config]="config" [items]="newItems" [(ngModel)]="selectedCategories"
                        (selectedChange)="onSelectedChange($event)" (filterChange)="onFilterChange($event)">
                    </ngx-treeview>
                </div> 
            </article>
        </div>

        <div class="col-md-9" (ngClass)="showCategoryFilter?'col-md-9':'col-md-12'">
            <div class="center">
                <span class="fa fa-loading color-primary" *ngIf="isLoaded==false"></span>
            </div>
            <div class="search-results" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
                (scrolled)="onScroll()">


                <div class="row">
                    <ng-container *ngIf="items.length==0&&IsInventoryLoaded">
                        <div class="loaderCard" style="margin-top: 25px;">
                            <img  src="../../assets/img/nodata-found.png">
                           
                            <h2 >No Data Found</h2>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!IsInventoryLoaded">
                        <div class="loaderCards" style="padding:173px 347px">
                            <div class="loader" ></div>
                            <h3 >Please Wait...</h3>
                        </div>
                    </ng-container>
                 <div style="margin-bottom: 20px;">
                    <h2>Products
                    <button *ngIf="Catgoryfiltersummery!=''||searchText!=''" style="float: right;
                    color: #d9230f;
                    margin-top: 10px;"   class="btn btn-light" (click)="Clear()"><i class="fa fa-filter"></i></button>
                  </h2>
                  <hr>

                  <!-- <button style=" margin-bottom: 20px;float: right;color: #d9230f"   class="btn btn-light" (click)="Clear()">clear Filter</button> -->
               
                <div class="row">
                   
                    <a class="chip" *ngFor="let item of Catgoryfiltersummery" style="margin-right: 20px; margin-bottom: 10px;width: auto;"  > {{(item.Name.length>15)?
                        (item.Name|slice:0:15)+'...':(item.Name)}}      <span class="closebtn" (click)="selectCategory(item.Id)" >&times;</span> </a>
                   
                </div>
                 </div>  
                    <div class="col-12 col-sm-6 col-md-6" style="margin-bottom: 32px;" *ngFor="let item of items">
                        <figure class="product-list mb-3 border p-3">
                            <div class="row">
                                <div class="col-3 col-md-3">
                                    <a (click)="RouterLink(item)" class="img-wrap">
                                        <img  class="imges" src="{{item.ImagePath}}">
                                    </a>
                                    <div class="marginText">
                                        <a style="top: -35px;
                                        left: 8px;" class="btn-link badge badge-warning text-danger ">
                                            {{item.Discount}}%</a> &nbsp;

                                    </div>
                                </div>
                                <div class="info-wrap col-7 col-md-8">
                                    <div class="row">
                            
                                        <div class="col-9 col-md-9"  >
                                        <p class="title" >{{item.ProductName}} </p>
                                        
                                    </div>
                                    <div  class="col-2 col-md-3">
                                        <a (click)="addToWhishlist(item)" style="    float: right;
                                        margin-right: -45px;
                                        margin-top: -12px;" *ngIf="!item.wishlist" class="btn btn-sm btn-light"> <i class="fa fa-heart"></i> </a>
                                        <a style="    float: right;
                                        margin-right: -45px;
                                        margin-top: -12px;"  (click)="RemoveFromWishlist(item)" *ngIf="item.wishlist" class="btn btn-sm btn-light"> <i class="fa fa-heart" style="color: red;"></i> </a>
                                    </div>
                                    </div>
                                    <small class="text-muted">{{item.CategoryName}}</small>
                                    <div class="row">
                                        <div class="price-wrap mb-2 col-8 col-md-8" >
                                            <span class="price">MRP: <del>₹ {{item.newValue}}</del><a style="color: red;"> | ₹ {{item.Cost}}</a></span>
                                        </div>
                                        <div class="price-wrap mb-2 col-4 col-md-4">
                                            <span class="price"><a style="color: red;">₹ {{item.Cost
                                                *item.selectedQuantity}}</a></span>
                                        </div>
                                    </div>
                                    <div class="price-wrap mb-2">
                                        <span class="price"><a class="">MOQ : {{item.MinimumOrderQuantity}}</a></span>
                                        <div class="dropdown">
                                            </div>
                                    </div>


                                    <div class="input-group input-group-sm input-spinner" *ngIf="item.isAddedToCart" style="margin-left: -109px;
                                    margin-top: 20px;
                                    margin-bottom: -53px;">
                                        <div class="input-group-prepend">
                                            <button class="btn btn-sm btn-light" (click)="updateCart('minus',item)"
                                            type="button" id="button-minus"> <i class="fa fa-minus"></i>
                                        </button>
                                        </div>
                                        <input type="text" readonly class="form-control"
                                            value="{{item.selectedQuantity}}">
                                        <div class="input-group-append">
                                            <button class="btn btn-sm btn-light" (click)="updateCart('plus',item)"
                                            type="button" id="button-plus"> <i class="fa fa-plus"></i>
                                        </button>
                                          
                                        </div>
                                    </div>
                                    <div style="margin-left: -109px;
                                    margin-top: 20px;
                                    margin-bottom: -13px;">
                                    <a (click)="addToCart(item)"  *ngIf="!item.isAddedToCart&&item.selectedQuantity==0"
                                        class="btn btn-secondary btn-sm buttons">
                                        <i class="fa fa-shopping-cart"></i> Add To Cart
                                    </a> &nbsp;
                                </div>

                                </div>
                            </div>
                        </figure>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>