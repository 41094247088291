import { Component, OnInit } from '@angular/core';
import { ProductService } from '../../main-content/sevices/product.service';
import { environment } from '../../../environments/environment';
import { SelectedProductService } from './../../main-content/sevices/selected-product.service';
import { NumericLiteral } from 'typescript';
import { NotificationService } from '../../../app/_services/notification.service';
import { StateService } from '../../main-content/sevices/state.service';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../_services';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {

  public pageId: number = 0;
  public items: any = [];
  user: any;

 public wishlist:any=[];

  constructor(private Toaster: ToastrService,private authenticationService: AuthenticationService,private route: Router, private stateService: StateService, private productService: ProductService, private notifyService: NotificationService, private selectedProductService: SelectedProductService) { }

  ngOnInit(): void {
    this.user = this.authenticationService.currentUser;
 
    this. GetWishListfromService();
    this.getProducts();
    
  }
  addToCart(currentItem) {
    debugger
    currentItem.isAddedToCart = true;
    currentItem.selectedQuantity=currentItem.MinimumOrderQuantity
    if(this.selectedProductService.items=='')
    {
    
      this.selectedProductService.add(currentItem)
      this.selectedProductService.items.forEach(element => {
     
       
       
         if(element.Id==currentItem.Id)  
         {
           element.selectedQuantity=0
       }
      
       });  
    }
    
    this.selectedProductService.items.forEach(element => {
     
      //  if(element.Id!=currentItem.Id )   
      //  {
        if (this.isAlreadyColorName(currentItem.Id) != true)
        {
          this.selectedProductService.add(currentItem)
        
      
      }  
       
      //  }
       else
       {
        if(element.Id==currentItem.Id)  
        {
        element.selectedQuantity=element.selectedQuantity+currentItem.MinimumOrderQuantity;
      }
       }
      });  
    // this.selectedProductService.add(currentItem);
    this.notifyService.showSuccess("Add to Card Succcessfully", currentItem.ProductName)
  
    
  }
  isAlreadyColorName(id:any)
  {
    debugger;
    for (var i = 0; i < this.selectedProductService.items.length; i++) {
      if (this.selectedProductService.items[i].Id === id) {
          return true;
      }
  }
  return false;
  }
  RemoveFromWishlists(item:any)
  {
    debugger;
    var id
    this.wishlist.forEach(element => {
      if(element.productattrId==item.Id)
      {
           id=element.id
      }
    });
    this.productService.RemoveFromWishlist(id).subscribe(resp => {
      debugger;
      if (resp.success == true) {
        // this.GetWishListfromService();
        this.productService.GetWishLists(this.user.source._value.userName).subscribe(resp => {
          debugger;
          this.wishlist = resp;
          if(this.wishlist.length==0)
          {
            this.items.forEach(element => {
              
              
                   element.wishlist=false;
            
              
            });
            
          }
          this.wishlist.forEach(element1 => {
            this.items.forEach(element => {
              if(element1.productattrId==element.Id){
              
                   element.wishlist=true;
                }  
                else
                {
                  if(element1.productattrId!=element.Id)
                  {
                    if(element.Id==item.Id)
                    {
                    element.wishlist=false;
                  }
                  }
                }
               
            });
            
           
         });
        
        })
      // this.getProducts();
        this.Toaster.success("Success! Wishlist Updated")
     
    }
       
    })
  }
  onScroll() {

    this.pageId = this.pageId + 1;
    this.getProducts();
  }
  goToProductDetail(itemToSet) {
    this.stateService.setState(itemToSet);
    this.route.navigateByUrl("/productdetail");
  }
  updateCart(type, item) {
    if (type == "plus") {
      item.selectedQuantity = item.selectedQuantity + item.MinimumOrderQuantity;
    } else {
      if (item.selectedQuantity >0)
      item.selectedQuantity = item.selectedQuantity -  item.MinimumOrderQuantity;
       if(item.selectedQuantity==0)
       {
        item.isAddedToCart=false;
       }
    }
    this.selectedProductService.items.forEach(element => {
      if (element.Id == item.Id) {
        element.selectedQuantity = item.selectedQuantity;
      }

    });
    this.selectedProductService.syncItems();
  }
  getProducts() {
    var _model = { displayLength: 20, displayStart: this.pageId * 20, searchText:'', filtertext: '', Categories:'', lowprice: null, highprice: null, isFeatured: "0", ProductId: "" };
    this.productService.getProducts(_model).subscribe(resp => {
        debugger
      if (resp.aaData != undefined || resp.aaData.length > 0) {
        resp.aaData.forEach(element => {
          let itemToPush:any={};
          resp.aoColumns.forEach((col, i) => {
            if (i == 13) {
              var array = element[13].split(',');
              element[i] = environment.globalImagePath + 'ProductImages/' + "Thumb/" + array[0];
            }
            itemToPush[col] = element[i];
            itemToPush["isAddedToCart"] = false;
            itemToPush["wishlist"] = false;
            itemToPush["newValue"]=element[5]+(element[5]*element[16])/100
           
          });

        
          // console.log(itemToPush);
         
          if( itemToPush["MinimumOrderQuantity"]==null)
          {
            itemToPush["MinimumOrderQuantity"]=1
          }
          itemToPush["selectedQuantity"] =0 ;


          this.selectedProductService.items.forEach(element1 => {

             if(element1.Id==element[6]){
              itemToPush.isAddedToCart=true;
              itemToPush.selectedQuantity=element1.selectedQuantity
             }
            
          });
          this.wishlist.forEach(element1 => {

            if(element1.productattrId==element[4]){
            //  itemToPush.isAddedToCart=true;
            //  itemToPush.selectedQuantity=element1.selectedQuantity
                itemToPush["wishlist"]=true;
            }
           
         });

          this.items.push(itemToPush);
          this.items.forEach(element => {
            if(element.selectedQuantity==0)
      {
        element.isAddedToCart=false;
      }
    })
        });

      }
    });
  }
  RouterLink(item)  {
    debugger;
    localStorage.setItem('MinimumOrderQuantity',item.MinimumOrderQuantity)
    this.route.navigate(['/productdetail'], { queryParams: { Id: item.Id } });
  }
  addToWhishlist(item:any)
  {
    debugger;
    var wishListmodel = { ProductId: item.Id, Productattrid: item.Id, CustomerId: -1, UserID: this.user.source._value.userName };
    this.productService.wishlisht(wishListmodel).subscribe(resp => {
      debugger;
      if (resp.success == true) {
        // $scope.iconclass = "angel icon-heart";
        this.Toaster.success( "is added to Wishlist !",item.ProductName);
     
       this.GetWishListfromService();
      //  this.getProducts();
      }
    })
  }
  GetWishListfromService()
  {
    debugger;
   
      this.productService.GetWishLists(this.user.source._value.userName).subscribe(resp => {
        debugger;
        this.wishlist = resp;
     
        this.wishlist.forEach(element1 => {
          this.items.forEach(element => {
            if(element1.productattrId== element.Id){
              
                         element.wishlist=true;
                      }  
                     
                     
          });
          
         
       });
      
      })

  
  }

}
