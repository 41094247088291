import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { Router, RouterModule } from '@angular/router';
if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

let onDeviceReady = () => {
  document.addEventListener("resume", onResume, false);
}
document.addEventListener('deviceready', onDeviceReady, false);



let onResume = () => {

  // here i need the Router and use it like: router.navigate(['home'])
}
